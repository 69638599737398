import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import { Helmet } from "react-helmet";

// Components
import { IconTwo } from "../components/icons/wolfe-icon-two";
import { IconOne } from "../components/icons/wolfe-icon-one";

const Page = styled.div`
  height: 100%;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;

  overflow: hidden;

  padding: 36px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  font-size: 19px;
  line-height: 25px;
  letter-spacing: 0.1px;

  font-feature-settings: "liga", "onum";
  text-align: center;

  & p {
    margin: 0;
  }

  & a:hover {
    color: #a2a2a2;
  }

  & svg {
    width: 120px;
    height: auto;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  @media (max-width: 500px) {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.05px;

    & svg {
      width: 80px;
      height: auto;
    }
  }
`;

const CenterWrapper = styled.div`
  max-width: 380px;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  position: relative;

  & > p {
    width: 100%;
  }
`;

const PageNotFound = () => (
  <Div100vh>
    <Page>
      <Helmet title={`Page Not Found – Wolfe Hall`} />

      <Container>
        <CenterWrapper>
          <IconTwo />

          <p>
            404: Page Not Found <br />
            Return to <Link to={`/projects`}>Projects</Link>
          </p>
        </CenterWrapper>

        <IconOne />
      </Container>
    </Page>
  </Div100vh>
);

export default PageNotFound;
